const parser = require("./parse-query.js");
const apiReq = require("./api-request.js");
const { endpoints } = require("./endpoints.js");

function doSearch (cfg, query) {
  cfg.eventHandler("search", query);

  return apiReq.get(
    cfg.endpoint,
    cfg.apiKey,
    endpoints.ifcRaw + query
  );
};

function handleUploadApiResp(err, json) {
  loadSolid(json);
}

function pushSearch(cfg, query) {
  const obj = parser.parseQuery(location.search);
  obj.q = query;

  pushState(obj);
  const json = doSearch(cfg, query);
  //handleUploadApiResp(err, json);
}

function mkUrl(qs) {
  const keys = Object.keys(qs);
  return (
    "?" + keys.map((key) => key + "=" + encodeURIComponent(qs[key])).join(",")
  );
}

function pushState(qs) {
  const url = mkUrl(qs);
  history.pushState(qs, qs, url);
}

function popState(cfg, event) {
  if (event.state && event.state != null && event.state.q) {
    const json = doSearch(cfg, event.state.q);
    //handleUploadApiResp(err, json);
  }
}

function clearSearch(cfg, el) {
  pushSearch(cfg, "");
}

async function processQuery(cfg, qs) {
  const obj = parser.parseQuery(qs);

  if (obj.q) {
    const json = await doSearch(cfg, obj.q);
    pushState(obj);
    return json;
  }

  return null;
}

exports.processQuery = processQuery;

const THREE = require("./three.js");
const jointCamera = require("./joint-camera.js");
const modelCamera = require("./model-camera.js");

function useJointViewer() {
  return jointCamera.initCamera();
}

function useModelViewer() {
  return modelCamera.initCamera();
}

function setCameraPosition(target, camera) {
  const distanceToMove = moveCameraDistance(target, camera);
  const newPosition = camera.position.add(distanceToMove);

  camera.position += newPosition;
  camera.updateProjectionMatrix();
}

function moveCameraDistance(target, camera) {
  const newTarget = new THREE.Vector3().copy(target);

  const length = newTarget.sub(camera.position).length();
  const dist = 2;

  const normalizedTarget = newTarget.normalize();
  const distanceToMove = normalizedTarget.multiplyScalar(length - dist);
  return distanceToMove;
}

export { useJointViewer, useModelViewer, setCameraPosition, moveCameraDistance }

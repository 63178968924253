const THREE = require("./three.js");

function getAmbientLight() {
  return new THREE.AmbientLight(0x404040);
}

function getDirectionalLight() {
  var dir = new THREE.DirectionalLight(0xffffff, 0.2); // soft white light
  dir.position = new THREE.Vector3(0.5, 0.5, 0.5);
  return dir;
}

function getHemisphereLight() {
  return new THREE.HemisphereLight(0xffffbb, 0x080820, 1.0);
}

function getDirectionalLight2() {
  return new THREE.DirectionalLight(0xffffff, 0.4);
}

exports.getAmbientLight = getAmbientLight;
exports.getDirectionalLight = getDirectionalLight;
exports.getHemisphereLight = getHemisphereLight;
exports.getDirectionalLight2 = getDirectionalLight2;

const THREE = require("./three.js");

function getSolidBounds(minP, maxP, vs) {
  for (let v = 0; v < vs.length; v += 3) {
    const vec = new THREE.Vector3(vs[v + 0], vs[v + 1], vs[v + 2]);
    minP.min(vec);
    maxP.max(vec);
  }
}

function getBoundingSize(solids) {
  if (solids.length == 0) throw new Error("solids is empty");

  const vs = solids[0].vertices;

  if (!vs)
    return {
      max: new THREE.Vector3(),
      min: new THREE.Vector3(),
    };

  const minP = new THREE.Vector3(vs[0], vs[1], vs[2]);
  const maxP = new THREE.Vector3(vs[0], vs[1], vs[2]);

  for (let s = 0; s < solids.length; s++)
    getSolidBounds(minP, maxP, solids[s].vertices);

  return {
    max: maxP,
    min: minP,
  };
}

export { getSolidBounds, getBoundingSize }

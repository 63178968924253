const cfg = require("./js/config.js").cfg;
const solidsteel = require("./js/solidsteel.js");
const search = require("./js/search.js");
const parser = require("./js/parse-query.js");

var canvas = document.getElementById("solidsteel");
if (!canvas) throw new Error("Solid Steel DOM element not found");

var searchEl = document.getElementById("search");

cfg.canvas = canvas;
cfg.searchBox = searchEl;
cfg.eventHandler = search.eventHandler;

var qs = parser.parseQuery(location.search);
search.initSearch(qs);

solidsteel.initSolidSteel(cfg);


const THREE = require("./three.js");
const PcgRandom = require("pcg-random");

const loadRawSolids = (solids, minP) => {
  const random = new PcgRandom(32);

  const meshGroup = new THREE.Group();
  const wireframeGroup = new THREE.Group();
  let centers = [];

  for (var i = 0; i < solids.length; i++) {
    const col = randomColor(random);
    const material = getMaterial(col);

    const solid = solids[i];
    const verts = new Float32Array(solid.vertices);
    const normals = new Float32Array(solid.normals);

    let { minB, maxB } = getMinMax(verts, minP);

    getSolidBounds(minB, maxB, verts);

    centers.push(getCenter(minB, maxB));

    const geom = getGeometry(solid.indices, verts, normals);

    const wireframe = getWireframe(geom, col);
    const mesh = getMesh(geom, material);

    wireframe.renderOrder = 1;
    mesh.renderOrder = 2;

    meshGroup.add(mesh);
    wireframeGroup.mainMesh = mesh;
    wireframeGroup.add(wireframe);
  }

  return { solidCenters: centers, meshGroup, wireframeGroup };
};

function getMinMax(verts, minP) {
  for (var v = 0; v < verts.length; v += 3) {
    verts[v + 0] -= minP.x;
    verts[v + 1] -= minP.y;
    verts[v + 2] -= minP.z;
  }

  const minB = new THREE.Vector3(verts[0], verts[1], verts[2]);
  const maxB = new THREE.Vector3(verts[0], verts[1], verts[2]);

  return { minB, maxB };
}

function getCenter(minB, maxB) {
  return new THREE.Vector3()
    .subVectors(maxB, minB)
    .multiplyScalar(0.5) //this moves solids down in view to ~center of canvas
    .add(minB);
}

function getGeometry(index, verts, normals) {
  const geom = new THREE.BufferGeometry();

  geom.setIndex(index);
  geom.addAttribute("position", new THREE.BufferAttribute(verts, 3));
  geom.addAttribute("normal", new THREE.BufferAttribute(normals, 3));
  geom.addAttribute("color", new THREE.BufferAttribute(normals, 3));

  return geom;
}

function getWireframe(geom, col) {
  const edges = new THREE.EdgesGeometry(geom);
  const edgesMat = new THREE.LineBasicMaterial({ color: col, linewidth: 1 });

  return new THREE.LineSegments(edges, edgesMat);
}

function getMesh(geom, material) {
  return new THREE.Mesh(geom, material);
}

function getMaterial(col) {
  return new THREE.MeshStandardMaterial({
    color: col,
    roughness: 0.6,
    metalness: 0.9,
    opacity: 0.9,
    transparent: true,
  });
}

function randomColor(random) {
  const r = random.number() * 0.5 + 0.5;
  const g = random.number() * 0.5 + 0.5;
  const b = random.number() * 0.5 + 0.5;

  return new THREE.Color(r, g, b);
}

function getSolidBounds(minP, maxP, vs) {
  for (var v = 0; v < vs.length; v += 3) {
    const vec = new THREE.Vector3(vs[v + 0], vs[v + 1], vs[v + 2]);
    minP.min(vec);
    maxP.max(vec);
  }
}

exports.loadRawSolids = loadRawSolids;

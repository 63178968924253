var searchEl = document.getElementById("search");
var headerEl = document.getElementById("header");
var clearSearchEl = document.getElementById("clear-search");

function eventHandler(event, ...args) {
  switch (event) {
    case "search":
      updateSearchState(args[0]);
      break;
  }
}

function updateSearchState(value) {
  searchEl.value = value;
  clearSearchEl.style.visibility = !!value ? "visible" : "hidden";
}

const initSearch = async (qs) => {
  //hide ui
  if (qs.ui === "0") {
    headerEl.style.display = "none";
  }

  if (searchEl)
    searchEl.onkeyup = function searchEnter(ev) {
      console.log(ev.target.value);
      if (ev.code == "Enter") pushSearch(ss, ev.target.value);

      updateSearchState(ev.target.value);
    };

  if (clearSearchEl)
    clearSearchEl.onclick = function (ev) {
      updateSearchState("");
    };
};

exports.initSearch = initSearch;
exports.eventHandler = eventHandler;

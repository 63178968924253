var cfg = {
    modelViewer: false,
    gui: false,
    canvas: {},
    root: "/solidsteel",
    endpoint: "https://api.razorcx.com",
    orgId: "e7a4a122-d555-4b56-ab96-2a67186608e7",
    apiKey: "0ca33063-7ffa-40cf-b74d-56e09338805f",
    catalogId: "0110acc789f78a11fd88def89bccbf06c28b164676", //"0102fe3c152627d99f8be1a8db08013168fdceb4d9",   //default joint view
    searchBox: {},
    eventHandler: {}
  };

  exports.cfg = cfg;

const Renderer = require("./three.js").WebGLRenderer;

function makeRenderer(opts) {
  const renderer = new Renderer(opts || {
    antialias: true,
    physicallyCorrectLights: true,
    alpha: true,
  });

  renderer.setSize(window.innerWidth, window.innerHeight);

  return renderer
}

exports.makeRenderer = makeRenderer;

const Camera = require("./three.js").PerspectiveCamera;

const initCamera = () => {
  const cam = new Camera(30, window.innerWidth / window.innerHeight, 0.01, 500);

  cam.position.z = -20;
  cam.position.x = 30;
  cam.position.y = 120;

  const normal = cam.position.normalize();

  cam.position.z = normal.z * 100;
  cam.position.x = normal.x * 100;
  cam.position.y = normal.y * 100;

  cam.zoom = 1;

  return cam;
};

exports.initCamera = initCamera;

const THREE = require("./three.js");
const OrbitControls = require("three-orbit-controls")(THREE);
const dat = require("dat.gui");
const { makeRenderer } = require("./renderer.js");
const apiReq = require("./api-request.js");
const lights = require(".//lights.js");
const { endpoints } = require("./endpoints.js");
const { loadRawSolids } = require("./load-raw-solids.js");
const { processQuery } = require("./process-query.js");
const { getSolidBounds, getBoundingSize } = require("./solids-helper.js");
const { useJointViewer, useModelViewer, setCameraPosition, moveCameraDistance } = require("./camera.js");

const up = [0, 0, 1];

function animate(solidSteel) {
  if (solidSteel.controls) {
    solidSteel.controls.target = solidSteel.target;
    solidSteel.controls.update();
  }

  if (solidSteel) {
    solidSteel.renderer.render(solidSteel.scene, solidSteel.camera);
  }

  requestAnimationFrame(animate.bind(null, solidSteel));
}

async function initSolidSteel(config={}) {
  if (!config) throw new Error("Configuration required");

  const canvas = config.canvas || document.body;
  const renderer = makeRenderer()
  const gui = config.gui ? new dat.GUI() : undefined;

  let solidSteel = {canvas, renderer, gui, config}

  if (!!config.modelViewer) {
    const camera = useModelViewer()
    solidSteel.camera = camera
    solidSteel.controls = new OrbitControls(camera, canvas);
  }

  if (!config.catalogId && !config.data)
    throw new Error("catalogId or data argument required");

  canvas.removeChild(canvas.childNodes[1]);
  canvas.appendChild(renderer.domElement);

  const solids = await processQuery(config, location.search);

  addWindowEventListeners(solidSteel);

  await initCanvas(solidSteel, solids);

  animate(solidSteel);

  return solidSteel
}

async function initCanvas(solidSteel, solids) {
  if (solids) loadSolids(solidSteel, solids);
  else if (!solids) {
    if (solidSteel.config.catalogId) {
      const solids = await fetchJoint(solidSteel.config, solidSteel.config.catalogId);
      if (solids) loadSolids(solidSteel, solids);
    } else {
      if (config.data) {
        loadSolids(solidSteel, solidSteel.config.data);
      }
    }
  }
}

function loadSolids(solidSteel, solids) {
  if (!solids) return;

  const bounds = getBoundingSize(solids);

  const camera = useJointViewer();
  solidSteel.camera = camera

  const { solidCenters, threeGroup, meshGroup, wireframeGroup } = loadRawSolids(
    solids,
    bounds.min
  );

  const scene = new THREE.Scene()
  solidSteel.scene = scene

  scene.mainMesh = meshGroup;
  scene.add(meshGroup);
  scene.add(wireframeGroup);

  setLights(scene);

  solidSteel.target = solidCenters[0];

  if (up && solidSteel.camera) {
    solidSteel.camera.up.set(up[0], up[1], up[2]);
  }

  setCameraPosition(solidSteel.target, solidSteel.camera);
  setOrbitControls(solidSteel);
}

function setOrbitControls(solidSteel) {
  const controls = new OrbitControls(solidSteel.camera, solidSteel.canvas);
  solidSteel.controls = controls
  controls.target = solidSteel.target;
}

function setLights(scene) {
  scene.add(lights.getAmbientLight());
  scene.add(lights.getDirectionalLight());
  scene.add(lights.getHemisphereLight());
  scene.add(lights.getDirectionalLight2());
}

function addWindowEventListeners(solidSteel) {
  const onResize = onWindowResize.bind(null, solidSteel)
  const onKey = onKeyPress.bind(null, solidSteel)

  window.addEventListener("resize", onResize, false);
  //window.addEventListener("popstate", popState)
  window.addEventListener("keyup", onKey);
}

function onWindowResize(solidSteel) {
  const width = window.innerWidth;
  const height = window.innerHeight;

  if (solidSteel.camera) {
    solidSteel.camera.aspect = width / height;
    solidSteel.camera.updateProjectionMatrix();
  }

  solidSteel.renderer.setSize(width, height);
  solidSteel.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}

function onKeyPress(solidSteel, event) {
  switch (event.code) {
    case "Space":
      break;

    case "KeyW":
      toggleWireframe(solidSteel)
      break;

    default:
  }
}


function toggleWireframe(solidSteel) {
  solidSteel.scene.mainMesh.visible = !solidSteel.scene.mainMesh.visible
}

function fetchJoint(config, joint, done) {
  const catalogId = joint.catalogId || joint;
  return apiReq.get(
    config.endpoint,
    config.apiKey,
    endpoints.jointSolids + catalogId
  );
}

exports.initSolidSteel = initSolidSteel;

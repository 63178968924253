const Camera = require("./three.js").PerspectiveCamera;

const initCamera = () => {
  ss.camera = new Camera(75, window.innerWidth / window.innerHeight, 1, 30000);

  ss.camera.position.z = -1000;
  ss.camera.position.x = -1000;
  ss.camera.position.y = -1000;
  ss.camera.updateProjectionMatrix();

  return cam;
};

exports.initCamera = initCamera;
